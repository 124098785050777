<template>
  <v-container fluid class="text-center">
    <v-row>
      <v-col cols="12">
        <v-card class="elevation-5 mt-5 mx-5">
          <v-toolbar dense dark color="secondary" elevation="2">
            <v-toolbar-title>
              Formularios
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn text small @click="dialogs.new = true">
              Agregar
              <v-icon right>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-title>
            <v-btn small :loading="loading" elevation="0" color="secondary" outlined class="mx-2" @click="refresh()">
              Actualizar
              <v-icon right>mdi-refresh</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
          </v-card-title>
          <v-data-table v-model="selected" :headers="headers" :items="items" :loading="loading" :search="search"
          :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }" :items-per-page="10" item-key="id" class="elevation-1" dense>
            <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item)">mdi-pencil</v-icon></template><span>Editar</span></v-tooltip>
              <v-tooltip top><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item, 'companies')">mdi-domain</v-icon></template><span>Empresas asignadas</span></v-tooltip>
              <v-tooltip top><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="copy(item.idformulario)">mdi-content-duplicate</v-icon></template><span>Copiar</span></v-tooltip>
              <v-tooltip top><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="updateProfile(item.idformulario)">mdi-shield-account</v-icon></template><span>Actualizar perfil</span></v-tooltip>
              <v-tooltip top><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="downloadAnswers(item)">mdi-download</v-icon></template><span>Descargar respuestas</span></v-tooltip>
              <v-tooltip right><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="error" dark v-bind="attrs" v-on="on" :disabled="loading" @click="remove(item.idformulario)">mdi-delete</v-icon></template><span>Eliminar</span></v-tooltip>
            </template>
            <template v-slot:[`item.publico`]="{ item }">
              <v-btn v-if="item.publico" x-small elevation="0" color="#ffde59" rounded>Público</v-btn>
              <v-btn v-else x-small elevation="0" color="blue-grey" rounded dark>Privado</v-btn>
            </template>
            <template v-slot:[`footer.page-text`]="props">
              Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
            </template>
            <template v-slot:no-data>
              No hay información disponible
            </template>
            <template v-slot:no-results>
              No se obtuvieron resultados
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogs.new" persistent scrollable max-width="1100px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Nuevo formulario</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="save()">
            Guardar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text style="max-height: 560px;">
          <v-container>
            <v-form ref="formNew" lazy-validation>
              <v-row>
                <v-col cols="4">
                  <v-text-field v-model="forms.new.nombre" label="Nombre" :rules="rules.nombre" :disabled="loading" required></v-text-field>
                </v-col>
                <v-col cols="8">
                  <v-text-field v-model="forms.new.descripcion" label="Descripción" :rules="rules.descripcion" :disabled="loading" required></v-text-field>
                </v-col>
              </v-row>
              <template v-for="(item, idx) in forms.new.preguntas">
                <v-divider class="ma-5"></v-divider>
                <v-row justify="center">
                  <span class="font-weight-bold">Pregunta {{ (idx + 1) }}</span>
                </v-row>
                <v-row :key="idx">
                  <v-col cols="2">
                    <v-text-field v-model="item.id" label="Id" :rules="rules.pregunta.id" :disabled="loading" dense outlined required></v-text-field>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field v-model="item.titulo" label="Título" :rules="rules.pregunta.titulo" :disabled="loading" dense outlined required></v-text-field>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field v-model="item.subtitulo" label="Subtítulo" :rules="rules.pregunta.subtitulo" :disabled="loading" dense outlined required></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-autocomplete v-model="item.tipo" :items="catalogues.tipos" label="Tipo" :rules="rules.pregunta.tipo" item-text="text" item-value="value" :disabled="loading" dense outlined persistent-hint></v-autocomplete>
                  </v-col>
                  <v-col cols="2">
                    <v-btn color="blue-grey" :disabled="loading" outlined elevation="0" @click="openOptions(idx)">
                       + Opciones ({{ item.opciones.length }})
                    </v-btn>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete v-model="item.categoria" :items="catalogues.categorias" label="Categoría" item-text="nombre" item-value="id" :disabled="loading" dense outlined persistent-hint></v-autocomplete>
                  </v-col>
                  <v-col cols="2">
                    <v-switch v-model="item.requerido" label="Requerido" class="my-0" :disabled="loading" dense></v-switch>
                  </v-col>
                  <v-col cols="1">
                    <v-menu offset-x>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon :disabled="loading" v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item @click="moveUpQuestion(idx)">
                          <v-list-item-content>
                            <v-list-item-title>Mover</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-icon><v-icon small color="primary">mdi-arrow-up</v-icon></v-list-item-icon>
                        </v-list-item>
                        <v-list-item @click="moveDownQuestion(idx)">
                          <v-list-item-content>
                            <v-list-item-title>Mover</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-icon><v-icon small color="primary">mdi-arrow-down</v-icon></v-list-item-icon>
                        </v-list-item>
                        <v-list-item @click="removeQuestion(idx)">
                          <v-list-item-content>
                            <v-list-item-title>Eliminar</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-icon><v-icon small color="error">mdi-close</v-icon></v-list-item-icon>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </template>
            </v-form>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small outlined :disabled="forms.new.preguntas.length <= 1 || loading" color="primary" class="mx-2" @click="popQuestion()">
            Quitar pregunta
          </v-btn>
          <v-btn small outlined :disabled="forms.new.preguntas.length >= 50 || loading" color="primary" class="mx-2" @click="addQuestion()">
            Agregar pregunta
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.update" persistent scrollable max-width="1200px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Actualizar formulario</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="update()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text style="max-height: 560px;">
          <v-container>
            <v-form ref="formUpdate" lazy-validation>
              <v-row>
                <v-col cols="4">
                  <v-text-field v-model="forms.update.nombre" label="Nombre" :rules="rules.nombre" :disabled="loading" required></v-text-field>
                </v-col>
                <v-col cols="8">
                  <v-text-field v-model="forms.update.descripcion" label="Descripción" :rules="rules.descripcion" :disabled="loading" required></v-text-field>
                </v-col>
              </v-row>
              <template v-for="(item, idx) in forms.update.preguntas">
                <v-divider class="ma-5"></v-divider>
                <v-row justify="center">
                  <span class="font-weight-bold">Pregunta {{ (idx + 1) }}</span>
                </v-row>
                <v-row :key="idx">
                  <v-col cols="2">
                    <v-text-field v-model="item.id" label="Id" :rules="rules.pregunta.id" :disabled="loading" dense outlined required></v-text-field>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field v-model="item.titulo" label="Título" :rules="rules.pregunta.titulo" :disabled="loading" dense outlined required></v-text-field>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field v-model="item.subtitulo" label="Subtítulo" :rules="rules.pregunta.subtitulo" :disabled="loading" dense outlined required></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-autocomplete v-model="item.tipo" :items="catalogues.tipos" label="Tipo" :rules="rules.pregunta.tipo" item-text="text" item-value="value" :disabled="loading" dense outlined persistent-hint></v-autocomplete>
                  </v-col>
                  <v-col cols="2">
                    <v-btn color="blue-grey" :disabled="loading" outlined elevation="0" @click="openOptions(idx)">
                       + Opciones ({{ item.opciones.length }})
                    </v-btn>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete v-model="item.categoria" :items="catalogues.categorias" label="Categoría" item-text="nombre" item-value="id" :disabled="loading" dense outlined persistent-hint></v-autocomplete>
                  </v-col>
                  <v-col cols="2">
                    <v-switch v-model="item.requerido" label="Requerido" class="my-0" :disabled="loading" dense></v-switch>
                  </v-col>
                  <v-col cols="1">
                    <v-menu offset-x>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon :disabled="loading" v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item @click="moveUpQuestion(idx)">
                          <v-list-item-content>
                            <v-list-item-title>Mover</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-icon><v-icon small color="primary">mdi-arrow-up</v-icon></v-list-item-icon>
                        </v-list-item>
                        <v-list-item @click="moveDownQuestion(idx)">
                          <v-list-item-content>
                            <v-list-item-title>Mover</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-icon><v-icon small color="primary">mdi-arrow-down</v-icon></v-list-item-icon>
                        </v-list-item>
                        <v-list-item @click="removeQuestion(idx)">
                          <v-list-item-content>
                            <v-list-item-title>Eliminar</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-icon><v-icon small color="error">mdi-close</v-icon></v-list-item-icon>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </template>
            </v-form>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small outlined :disabled="forms.update.preguntas.length <= 1 || loading" color="primary" class="mx-2" @click="popQuestion()">
            Quitar pregunta
          </v-btn>
          <v-btn small outlined :disabled="forms.update.preguntas.length >= 50 || loading" color="primary" class="mx-2" @click="addQuestion()">
            Agregar pregunta
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.options" persistent scrollable max-width="500px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-form ref="formOptions" lazy-validation>
              <v-row>
                <v-col cols="10">
                  <v-text-field v-model="forms.options.nuevo" label="Nuevo valor" :rules="rules.opcion" :disabled="loading" required></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-btn fab icon small color="primary" class="mt-3" :disabled="loading" elevation="0" @click="addOption()">
                    <v-icon dark>mdi-plus-circle</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
            <template v-if="forms.options.valores.length">
              <v-divider class="ma-5"></v-divider>
              <v-row justify="center">
                <span class="font-weight-bold">Opciones</span>
              </v-row>
            </template>
          </v-container>
        </v-card-text>
        <v-card-text v-if="forms.options.valores.length" style="max-height: 300px;">
          <v-container>
            <v-list dense>
              <v-list-item-group>
                <v-list-item v-for="(item, idx) in forms.options.valores" :key="idx">
                  <v-list-item-content>
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon @click="removeOption(idx)">
                    <v-icon color="error">mdi-minus-circle</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-container>
        </v-card-text>
        <v-card-actions>  
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" color="blue-grey" @click="cancel()">
            Cancelar
          </v-btn>
          <v-btn text small :loading="loading" color="success" @click="saveOptions()">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.companies" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Empresas asignadas</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formCompanies" lazy-validation>
              <v-row>
                <template v-for="(item, idx) in forms.update.empresas">
                  <v-col cols="12" :key="idx">
                    <v-text-field :value="item.nombre" readonly required></v-text-field>
                  </v-col>
                </template>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.copy" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>¿Realmente desea copiar el formulario?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" color="blue-grey" @click="cancel()">
            Cancelar
          </v-btn>
          <v-btn text small :loading="loading" color="success" @click="copy()">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.remove" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>¿Realmente desea eliminar el formulario?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" color="blue-grey" @click="cancel()">
            Cancelar
          </v-btn>
          <v-btn text small :loading="loading" color="error" @click="remove()">
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
  </v-container>
</template>

<script>
import services from '@/utils/services'
import writeXlsxFile from 'write-excel-file'
import _ from 'lodash'

export default {
  name: 'Form',
  data: () => ({
    loading: false,
    success: false,
    msgSuccess: '',
    error: false,
    msgError: '',
    search: '',
    headers: [
      { text: 'Nombre', align: 'left', sortable: false, value: 'nombre' },
      { text: 'Descripción', align: 'left', sortable: false, value: 'descripcion' },
      { text: 'Preguntas', align: 'left', sortable: false, value: 'preguntas.length' },
      { text: 'Perfil', align: 'left', sortable: false, value: 'publico' },
      { text: 'Acciones', align: 'left', sortable: false, value: 'actions' }
    ],
    items: [],
    selected: [],
    copied: null,
    deleted: null,
    questionSelected: null,
    catalogues: {
      categorias: [],
      tipos: [
        { text: 'Texto simple', value: 'texto_simple'},
        { text: 'Numérico', value: 'numerico'},
        { text: 'Selección simple', value: 'seleccion_simple'},
        { text: 'Selección múltiple', value: 'seleccion_multiple'}
      ]
    },
    forms: {
      new: {
        nombre: '',
        descripcion: '',
        preguntas: [{
          id: '',
          titulo: '',
          subtitulo: '',
          categoria: '',
          tipo: '',
          opciones: [],
          requerido: false
        }]
      },
      update: {
        idformulario: '',
        nombre: '',
        descripcion: '',
        preguntas: [{
          id: '',
          titulo: '',
          subtitulo: '',
          categoria: '',
          tipo: '',
          opciones: [],
          requerido: false
        }]
      },
      options: {
        nuevo: '',
        valores: []
      }
    },
    rules: {
      nombre: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 100) || 'El campo excede la longitud máxima'
      ],
      descripcion: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 1000) || 'El campo excede la longitud máxima'
      ],
      pregunta: {
        id: [
          v => !!v || 'El campo es requerido',
          v => (v && v.length <= 50) || 'El campo excede la longitud máxima',
          v => /^[a-zA-ZñÑ0-9\_\-]{1,50}$/.test(v) || 'El campo es inválido'
        ],
        titulo: [
          v => !!v || 'El campo es requerido',
          v => (v && v.length <= 200) || 'El campo excede la longitud máxima'
        ],
        subtitulo: [
          v => _.isNil(v) || (!_.isNil(v) && v.length <= 100) || 'El campo excede la longitud máxima'
        ],
        tipo: [
          v => !!v || 'El campo es requerido'
        ]
      },
      opcion: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 100) || 'El campo excede la longitud máxima'
      ],
    },
    dialogs: {
      new: false,
      update: false,
      options: false,
      companies: false,
      copy: false,
      remove: false
    }
  }),
  mounted() {
    this.refresh()
  },
  methods: {
    async refresh() {
      this.loading = true
      const category = await this.axios.get(services.routes.form + '/category')
      if (category.data.data) {
        this.catalogues.categorias = category.data.data
      }
      this.axios.get(services.routes.form)
        .then(response => {
          this.items = response.data.data
        })
        .catch(error => {})
        .finally(() => {
          this.loading = false
        })
    },
    cancel() {
      if (this.dialogs.options) {
        this.$refs.formOptions.reset()
        this.forms.options = {
          nuevo: '',
          valores: []
        }
        this.questionSelected = null
        this.dialogs.options = false
      }
      else if (this.dialogs.new) {
        this.$refs.formNew.reset()
        this.forms.new = {
          nombre: '',
          descripcion: '',
          preguntas: [{
            id: '',
            titulo: '',
            subtitulo: '',
            categoria: '',
            tipo: '',
            opciones: [],
            requerido: false
          }]
        }
        this.dialogs.new = false
      }
      else if (this.dialogs.update) {
        this.$refs.formUpdate.reset()
        this.forms.update = {
          idformulario: '',
          nombre: '',
          descripcion: '',
          preguntas: [{
            id: '',
            titulo: '',
            subtitulo: '',
            categoria: '',
            tipo: '',
            opciones: [],
            requerido: false
          }]
        }
        this.dialogs.update = false
      }
      else if (this.dialogs.copy) {
        this.dialogs.copy = false
      }
      else if (this.dialogs.remove) {
        this.dialogs.remove = false
      }
      this.dialogs.companies = false
    },
    save() {
      if (this.$refs.formNew.validate()) {
        if (this.forms.new.preguntas.length) {
          this.loading = true
          let data = JSON.parse(JSON.stringify(this.forms.new))
          this.axios.post(services.routes.form, { data })
            .then(response => {
              this.cancel()
              this.refresh()
              this.success = true
              this.msgSuccess = 'Formulario creado exitosamente'
            })
            .catch(error => {
              this.error = true
              this.msgError = error.response.data.message || 'Servicio no disponible'
            })
            .finally(() => {
              this.loading = false
            })
        }
        else {
          this.error = true
          this.msgError = 'El formulario no contiene preguntas'
        }
      }
      else {
        this.error = true
        this.msgError = 'La información es incorrecta'
      }
    },
    view(data, type) {
      this.forms.update = {
        idformulario: data.idformulario,
        nombre: data.nombre,
        descripcion: data.descripcion,
        preguntas: JSON.parse(JSON.stringify(data.preguntas))
      }
      if (type === 'companies') {
        this.forms.update.empresas = []
        for (let i = 0; i < data.formularios_empresas.length; i++) {
          this.forms.update.empresas.push(data.formularios_empresas[i].empresa)
        }
        this.dialogs.companies = true
      }
      else {
        this.dialogs.update = true
      }
    },
    update() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true
        let data = JSON.parse(JSON.stringify(this.forms.update))
        this.axios.put(services.routes.form, { data })
          .then(response => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Formulario actualizado exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.error = true
        this.msgError = 'La información es incorrecta'
      }
    },
    copy(id) {
      if (!id) {
        this.loading = true
        let data = { idformulario: this.copied }
        this.axios.post(services.routes.form + '/copy', { data })
          .then(response => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Formulario copiado exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.copied = id
        this.dialogs.copy = true
      }
    },
    updateProfile(id) {
      this.loading = true
      let data = { idformulario: id }
      this.axios.put(services.routes.form + '/profile', { data })
        .then(response => {
          this.cancel()
          this.refresh()
          this.success = true
          this.msgSuccess = 'Formulario actualizado exitosamente'
        })
        .catch(error => {
          this.error = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
        })
    },
    downloadAnswers(item) {
      this.loading = true
      let params = { id: item.idformulario }
      this.axios.get(services.routes.form + '/answer', { params })
        .then(response => {
          if (response.data.data.length) {
            let data = []
            let headers = []
            for (let key in response.data.data[0]) {
              headers.push({
                value: key,
                fontWeight: 'bold'
              })
            }
            data.push(headers)
            for (let i = 0; i < response.data.data.length; i++) {
              let rows = []
              for (let key in response.data.data[0]) {
                rows.push({
                  type: String,
                  value: response.data.data[i][key]
                })
              }
              data.push(rows)
            }
            writeXlsxFile(data, { fileName: item.nombre + '.xlsx' })
            this.loading = false
          }
          else {
            this.error = true
            this.msgError = 'No hay información disponible'
          }
        })
        .catch(error => {
          this.error = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
        })
    },
    remove(id) {
      if (!id) {
        this.loading = true
        let id = this.deleted
        this.axios.delete(services.routes.form, { data: { data: { idformulario: this.deleted } } })
          .then(response => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Formulario eliminado exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.deleted = id
        this.dialogs.remove = true
      }
    },
    popQuestion() {
      const key = (this.dialogs.new) ? 'new' : 'update'
      this.forms[key].preguntas.pop()
    },
    addQuestion() {
      const key = (this.dialogs.new) ? 'new' : 'update'
      this.forms[key].preguntas.push({
        id: '',
        titulo: '',
        subtitulo: '',
        categoria: '',
        tipo: '',
        opciones: [],
        requerido: false
      })
    },
    openOptions(idx) {
      const key = (this.dialogs.new) ? 'new' : 'update'
      this.questionSelected = idx
      this.forms.options.nuevo = ''
      this.forms.options.valores = JSON.parse(JSON.stringify(this.forms[key].preguntas[idx].opciones))
      this.dialogs.options = true
    },
    addOption() {
      if (this.$refs.formOptions.validate()) {
        this.forms.options.valores.push(this.forms.options.nuevo)
        this.forms.options.nuevo = ''
        this.$refs.formOptions.reset()
      }
      else {
        this.error = true
        this.msgError = 'La información es incorrecta'
      }
    },
    removeOption(idx) {
      this.forms.options.valores.splice(idx, 1)
    },
    saveOptions() {
      const key = (this.dialogs.new) ? 'new' : 'update'
      this.forms[key].preguntas[this.questionSelected].opciones = JSON.parse(JSON.stringify(this.forms.options.valores))
      this.cancel()
    },
    moveUpQuestion(idx) {
      const key = (this.dialogs.new) ? 'new' : 'update'
      if (idx > 0 && idx <= (this.forms[key].preguntas.length - 1)) {
        let preguntas = JSON.parse(JSON.stringify(this.forms[key].preguntas))
        this.forms[key].preguntas = []
        let tmp = preguntas[idx - 1]
        preguntas[idx -1] = preguntas[idx]
        preguntas[idx] = tmp
        this.forms[key].preguntas = preguntas
      }
    },
    moveDownQuestion(idx) {
      const key = (this.dialogs.new) ? 'new' : 'update'
      if (idx < (this.forms[key].preguntas.length - 1)) {
        let preguntas = JSON.parse(JSON.stringify(this.forms[key].preguntas))
        this.forms[key].preguntas = []
        let tmp = preguntas[idx + 1]
        preguntas[idx + 1] = preguntas[idx]
        preguntas[idx] = tmp
        this.forms[key].preguntas = preguntas
      }
    },
    removeQuestion(idx) {
      const key = (this.dialogs.new) ? 'new' : 'update'
      if (this.forms[key].preguntas.length > 1) {
        this.forms[key].preguntas.splice(idx, 1)
      }
    }
  }
}
</script>

<style scoped>
</style>
